import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import loadable from '@loadable/component'
import { OptionsContext } from '/src/contexts/OptionsContext'

const Header = loadable(() => import('/src/components/Structure/Header/Header'))
const Footer = loadable(() => import('/src/components/Structure/Footer'))
const SiteInfo = loadable(() => import('/src/components/Structure/SiteInfo'))

const LayoutInner = ({
    isFrontPage,
    removeHeader,
    removeMainNav,
    removeCta,
    useAlternativeBranding,
    branding,
    removeFooter,
    utm,
    children
}) => {
    const [options] = useContext(OptionsContext)

    return (
        <>
            {options.siteInfo.addInfoStrip && <SiteInfo siteInfo={options.siteInfo} />}

            {!removeHeader && (
                <Header
                    isFrontPage={isFrontPage}
                    removeMainNav={removeMainNav}
                    removeCta={removeCta}
                    useAlternativeBranding={useAlternativeBranding}
                    branding={branding}
                    utm={utm}
                />
            )}

            {children}

            {!removeFooter && <Footer />}
        </>
    )
}

LayoutInner.propTypes = {
    /**
     * Whether the current page is the homepage or not (changes logo to either have an H1 or a span wrapped around it)
     */
    isFrontPage: PropTypes.bool,
    /**
     * Remove the header completely
     */
    removeHeader: PropTypes.bool,
    /**
     * Remove the main navigation and burger icon
     */
    removeMainNav: PropTypes.bool,
    /**
     * Remove CTA to lead gen journey
     */
    removeCta: PropTypes.bool,
    /**
     * Choose to replace the branding with something else
     */
    useAlternativeBranding: PropTypes.bool,
    /**
     * Branding to replace with
     */
    branding: PropTypes.oneOf(['insurance-hero']),
    /**
     * Remove the footer completely
     */
    removeFooter: PropTypes.bool,
    /**
     * Optional page UTMs - e.g. `?utm_campaign=campaign_name&utm_source=source_name`
     */
    utm: PropTypes.string,
    /**
     * Inner child elements
     */
    children: PropTypes.node.isRequired
}

export default LayoutInner
