import React, { useState, useEffect, useContext } from 'react'
import { graphql, Link } from 'gatsby'
import PropTypes from 'prop-types'
import loadable from '@loadable/component'
import { OptionsContext } from '/src/contexts/OptionsContext'
import { StaticImage } from 'gatsby-plugin-image'
import parse from 'html-react-parser'
import { twoDaysAgo } from '/src/functions/timeFormatter'
import { getAllParams } from '/src/functions/paramTools'

import ContentCard from '/src/components/Cards/ContentCard'
import LayoutInner from '/src/components/Layout/LayoutInner'
import Seo from '/src/components/Structure/Seo'
import './styles.scss'

const Image = loadable(() => import('/src/components/Media/Image'))
const ButtonLink = loadable(() => import('/src/components/Basic/Buttons/ButtonLink'))
const CheckList = loadable(() => import('/src/components/Lists/CheckList'))
const InsurerLogoGrid = loadable(() => import('/src/components/PageBuilder/layouts/global/InsurerLogoGrid'))
const StandaloneButton = loadable(() => import('/src/components/PageBuilder/layouts/standard/StandaloneButton'))
const TrustpilotReviewsWidget = loadable(() => import('/src/components/Trustpilot/TrustpilotReviewsWidget'))
const TrustpilotMicroWidget = loadable(() => import('/src/components/Trustpilot/TrustpilotMicroWidget'))
const CallToAction = loadable(() => import('/src/components/PageBuilder/layouts/standard/CallToAction'))
const FooterReduced = loadable(() => import('/src/components/Structure/FooterReduced'))

const AdvertorialTemplate = ({ data: { post } }) => {
    const [options] = useContext(OptionsContext)

    const [pageUtms, setPageUtms] = useState('')

    useEffect(() => {
        const addedUtms = post.acfAdvertorialPage?.utms || []
        setPageUtms(getAllParams(addedUtms))
    }, [post])

    const journeyLink = `${options.journey.mainJourney.link}${pageUtms}`
    const callToAction = {
        url: journeyLink,
        title: 'Get a quote today'
    }

    const date = twoDaysAgo()

    const preHeadline = post.acfAdvertorial?.preHeadline
    const headline = post.acfAdvertorial?.headline
    const authorName = post.acfAdvertorial?.authorName
    const authorImage = post.acfAdvertorial?.authorImage
    const featureLinkText = post.acfAdvertorial?.featureLinkText
    const contentOne = post.acfAdvertorial?.content1
    const contentTwo = post.acfAdvertorial?.content2
    const contentThree = post.acfAdvertorial?.content3
    const imageOne = post.acfAdvertorial?.image1
    const imageTwo = post.acfAdvertorial?.image2
    const disclaimers = post.acfAdvertorial?.disclaimers

    return (
        <LayoutInner removeMainNav={true} removeFooter={true} utm={pageUtms}>
            <Seo post={post} />

            <main className="c-advertorial">
                <div className="container-lg">
                    <div className="row">
                        <div className="col-xl-8 c-advertorial__content-column">
                            <article itemScope itemType="http://schema.org/Article">
                                <ContentCard>
                                    {preHeadline && <p className="c-advertorial__pre-headline">{preHeadline}</p>}

                                    <p className="c-advertorial__breadcrumb">
                                        Life Insurance | <Link to={journeyLink}>Save Money Today</Link>
                                    </p>

                                    {headline && <h1 className="c-advertorial__headline">{headline}</h1>}

                                    {authorName && (
                                        <div className="c-advertorial__author">
                                            <Image data={authorImage} className={'c-advertorial__author-image'} />

                                            <span className="c-advertorial__author-text">
                                                By <Link to={journeyLink}>{authorName}</Link> Last updated {date}
                                            </span>
                                        </div>
                                    )}

                                    <div className="c-advertorial__declaration">Advertorial</div>

                                    {featureLinkText && (
                                        <div className="c-advertorial__feature-cta">
                                            <span className="icon-chevron-right c-advertorial__feature-cta-icon" />
                                            <Link className="c-advertorial__feature-cta-link" to={journeyLink}>
                                                {featureLinkText}
                                            </Link>
                                        </div>
                                    )}

                                    {imageOne && (
                                        <Link
                                            className="c-advertorial__image-link c-advertorial__image-link--first"
                                            to={journeyLink}
                                        >
                                            <Image data={imageOne} className={'c-advertorial__image'} />
                                        </Link>
                                    )}

                                    {contentOne && <div className="c-advertorial__section">{parse(contentOne)}</div>}

                                    <div className="c-advertorial__section">
                                        <div className="c-age-quiz">
                                            <h3>Select your age:</h3>

                                            <div className="c-age-quiz__button-container">
                                                <Link className="c-age-quiz__button" to={journeyLink}>
                                                    20-29
                                                </Link>
                                                <Link className="c-age-quiz__button" to={journeyLink}>
                                                    30-39
                                                </Link>
                                                <Link className="c-age-quiz__button" to={journeyLink}>
                                                    40-49
                                                </Link>
                                                <Link className="c-age-quiz__button" to={journeyLink}>
                                                    50-59
                                                </Link>
                                                <Link className="c-age-quiz__button" to={journeyLink}>
                                                    60-69
                                                </Link>
                                                <Link className="c-age-quiz__button" to={journeyLink}>
                                                    70+
                                                </Link>
                                            </div>

                                            <ButtonLink
                                                data={{
                                                    url: journeyLink,
                                                    title: 'Calculate quote'
                                                }}
                                                size={'lg'}
                                                className={'c-age-quiz__cta'}
                                            />
                                        </div>
                                    </div>

                                    {contentTwo && <div className="c-advertorial__section">{parse(contentTwo)}</div>}

                                    <div className="c-advertorial__trustpilot">
                                        <TrustpilotReviewsWidget />
                                    </div>

                                    <div className="c-advertorial__section">
                                        <TrustpilotMicroWidget />
                                    </div>

                                    {contentThree && (
                                        <div className="c-advertorial__section">{parse(contentThree)}</div>
                                    )}

                                    <StandaloneButton
                                        blockSettings={{ paddingSize: 'sm' }}
                                        callToAction={callToAction}
                                        className={'c-advertorial__standalone-button'}
                                    />

                                    {imageTwo && (
                                        <Link className="c-advertorial__image-link" to={journeyLink}>
                                            <Image data={imageTwo} className={'c-advertorial__image'} />
                                        </Link>
                                    )}

                                    {disclaimers && (
                                        <div className="c-advertorial__section">
                                            <ul className="c-advertorial__disclaimers">
                                                {disclaimers.map((node, index) => (
                                                    <li key={index}>{node.disclaimer}</li>
                                                ))}
                                            </ul>
                                        </div>
                                    )}
                                </ContentCard>
                            </article>
                        </div>

                        <aside className="col-xl-4">
                            <div className="c-advertorial-sidebar">
                                <h3 className="c-advertorial-sidebar__title">
                                    No Life Insurance? Your family could be at risk!
                                </h3>

                                <ButtonLink
                                    data={callToAction}
                                    size={'lg'}
                                    variant={'inverse'}
                                    className={'c-advertorial-sidebar__button'}
                                />

                                <CheckList className="c-advertorial-sidebar__list">
                                    <li>Simple, easy, stress-free service</li>
                                    <li>Facts, not opinions</li>
                                    <li>No fees</li>
                                </CheckList>

                                <div className="c-advertorial-sidebar__trustpilot">
                                    <StaticImage
                                        src={'../../images/trustpilot-logo-white.png'}
                                        width={150}
                                        alt={'Trustpilot logo'}
                                    />

                                    <StaticImage
                                        src={'../../images/trustpilot-rating-5star.png'}
                                        width={150}
                                        alt={'Trustpilot 5 star rating'}
                                        className={'c-advertorial-sidebar__rating'}
                                    />

                                    <p className="c-advertorial-sidebar__rating-text">
                                        The UK's #1 Specialist Life Insurance Broker
                                    </p>
                                </div>

                                <StaticImage
                                    src={'../../images/pl-vertical-white.png'}
                                    width={125}
                                    alt={`${options.siteData.title} logo`}
                                    className={'c-advertorial-sidebar__logo'}
                                />
                            </div>
                        </aside>
                    </div>
                </div>
            </main>

            <InsurerLogoGrid backgroundColour={'dark'} />

            <CallToAction
                backgroundColour={'gradient'}
                titleArea={{
                    addTitleArea: true,
                    titlePosition: 'centered',
                    title: `Life Insurance Quotes from the UK's Top Providers`
                }}
                content={`<p>Compare the UK's top insurers with our simple service</p>`}
                addCta={true}
                callToAction={callToAction}
            />

            <FooterReduced />
        </LayoutInner>
    )
}

AdvertorialTemplate.propTypes = {
    /**
     * Post object
     */
    data: PropTypes.object.isRequired
}

export default AdvertorialTemplate

export const pageQuery = graphql`
    query AdvertorialById(
        # these variables are passed in via createPage.pageContext in gatsby-node.js
        $id: String!
    ) {
        # selecting the current post by id
        post: wpAdvertorial(id: { eq: $id }) {
            id
            slug
            title

            ...SeoForAdvertorial

            acfAdvertorial {
                utms {
                    addUtms
                    utmsList {
                        key
                        value
                    }
                }
                preHeadline
                headline
                authorName
                authorImage {
                    altText
                    localFile {
                        childImageSharp {
                            gatsbyImageData(layout: FIXED, width: 70, height: 70)
                        }
                    }
                }
                featureLinkText
                content1
                content2
                content3
                image1 {
                    altText
                    localFile {
                        childImageSharp {
                            gatsbyImageData(layout: FIXED)
                        }
                    }
                }
                image2 {
                    altText
                    localFile {
                        childImageSharp {
                            gatsbyImageData
                        }
                    }
                }
                disclaimers {
                    disclaimer
                }
            }
        }
    }
`
